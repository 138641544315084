<template>
  <div class="mg">
      <h2>防伪查询</h2>
  </div>
</template>

<script>
export default {
    name: 'check'
}
</script>

<style scoped>

</style>